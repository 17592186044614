/* eslint-disable import/no-unresolved */
import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

// Components
import Image from 'components/shared/Image'
import ParseContent from 'components/shared/ParseContent'

// Third Party
import parse from 'html-react-parser'

const Vacatures = ({ posts }) => {
  return (
    <div className="row">
      {posts.map((node) => (
        <div key={node.wordpress_id} className="col-md-6 pb-5 d-flex justify-content-center">
          <Vacature fields={node} />
        </div>
      ))}
    </div>
  )
}

const StyledReferentie = styled.div`
  width: 100%;
`

const StyledImage = styled(Image)`
  width: 100%;
  max-height: 300px;
`

const Content = styled(ParseContent)`
  opacity: .6;
  text-transform: none;
`

const DienstTitle = styled.div`
  font-size: ${props => props.theme.font.size.xm};
  line-height: 35px;
  text-transform: uppercase;
  position: relative;
  bottom: 0;
  width: 100%;
  padding: 15px 40px 15px 40px;

  background-color: ${props => props.theme.color.face.main};
  color: ${props => props.theme.color.text.light};
`

const Vacature = ({ fields }) => (
  <StyledReferentie className="position-relative">
    <Link to={fields.path}>
      <StyledImage src={fields.acf.preview.image} />
      <DienstTitle
        className="text-center"
        title={fields.slug}
      >
        {parse(fields.title)}
        <div>
          <Content content={fields.acf.preview.description} />
        </div>
      </DienstTitle>
    </Link>
  </StyledReferentie>
)

export default Vacatures