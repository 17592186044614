/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Elements
import HeroWorkWith from 'components/elements/workwith/HeroWorkWith'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import ParseContent from 'components/shared/ParseContent'

// Blog
import Vacatures from 'components/related/Vacatures'
import Blogik, { BlogConsumer } from 'components/shared/Blogik'

const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => {
  return (
    <Layout>
      <SEO yoast={yoast} path={path} />

      <HeroWorkWith fields={acf.banner} />

      <div className="container px-lg-5">
        <div className="px-md-5">
          <Blogik
            settings={{
              limit: 3,
              postType: 'vacatures'
            }}
          >
            <BlogConsumer>
              {({ hasPosts, posts }) => {
                return (
                  <div>
                    {hasPosts && (
                      <>
                        <Vacatures posts={posts} />
                      </>
                    )}

                    {!hasPosts && (
                      <ParseContent content="Geen resultaten" />
                    )}
                  </div>
                )
              }}
            </BlogConsumer>
          </Blogik>
        </div>
      </div>

      <div className="py-5" />
      <div className="py-5" />
      <div className="py-5" />
      
    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        banner {
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
                openGraph: fluid(maxWidth: 1500, toFormat: JPG) {
                  src
                }
              }
            }
          }
          description
          background {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
      
      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate
